@mixin bgImage($image, $width, $height) {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    background-image: url(/assets/img/#{$image});
    width: $width;
    height: $height;
}

.icon-close {
    @include bgImage('svg-pangolin/Icon-close-desktop-44.svg', 2rem, 2rem);
}

.icon-search {
    @include bgImage('svg-pangolin/Icon-search-48.svg', 3rem, 3rem);
}

.icon-search-gradient {
    @include bgImage('svg-pangolin/Icon-search-gradient-24.svg', 2rem, 2rem);
    position: absolute;
    top: 8px;
    left: 10px;
}

.icon-menu {
    @include bgImage('svg-pangolin/Icon-menu-48.svg', 3rem, 3rem);
}

.icon-gradient-close {
    @include bgImage('svg-pangolin/Icon-close-48.svg', 3rem, 3rem);
}

.icon-copy {
    @include bgImage('svg-pangolin/Icon-copy-24.svg', 1.25rem, 1.25rem);

}

.gradient-capsule {
    .icon-copy {
        position: absolute;
        top: 10px;
        right: 15px;
    }

    .icon-wallet {
        @include bgImage('svg-pangolin/Icon-wallet-gradient-48.svg', 40px, 40px);
    }

    .icon-instagram {
        @include bgImage('svg-pangolin/Icon-instagram-24.svg', 40px, 40px);
        position: absolute;
        top: 10px;
        right: 15px;
    }

    .icon-fb {
        @include bgImage('svg-pangolin/Icon-fb-24.svg', 40px, 40px);
        position: absolute;
        top: 10px;
        right: 15px;
    }

    .icon-twitter {
        @include bgImage('svg-pangolin/Icon-twitter-24.svg', 40px, 40px);
        position: absolute;
        top: 10px;
        right: 15px;
    }

    .icon-globe {
        @include bgImage('svg-pangolin/Icon-globe-24.svg', 40px, 40px);
        position: absolute;
        top: 10px;
        right: 15px;
    }

    .icon-verified {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 0.875rem;
        height: 0.875rem;
    }
}

.social-icon {
    display: flex;

    .icon-facebook {
        @include bgImage('svg-pangolin/Icon-fb-48.svg', 3rem, 3rem);
    }

    .icon-twitter {
        @include bgImage('svg-pangolin/Icon-twitter-48.svg', 3rem, 3rem);
    }

    .icon-instagram {
        @include bgImage('svg-pangolin/Icon-Instagram-48.svg', 3rem, 3rem);
    }
}

.icon-all-category {
    @include bgImage('svg-pangolin/Icon-all-category-24.svg', 1rem, 1rem);
}

.icon-selected {
    @include bgImage('svg-pangolin/Icon-selected-24.svg', 1rem, 1rem);
}

.icon-share {
    @include bgImage('svg-pangolin/Icon-share-grey-24.svg', 1.25rem, 1.25rem);
    position: absolute;
    top: 10px;
    left: 15px;
}

.icon-etherscan {
    @include bgImage('svg-pangolin/Icon-open etherScan-24.svg', 1.25rem, 1.25rem);
    display: inline-flex;
    vertical-align: middle;
}

.icon-verified {
    @include bgImage('svg-pangolin/Icon-verified-24.svg', 1.25rem, 1.25rem);
}

.icon-eth-gradient {
    @include bgImage('svg-pangolin/Icon-ETH-gradient-24.svg', 1.25rem, 1.25rem);
}

.icon-eth-white {
    @include bgImage('svg-pangolin/Icon-ETH-white-24.svg', 1.25rem, 1.25rem);
}

.icon-dollar-gradient {
    @include bgImage('svg-pangolin/Icon-dollar-gradient-24.svg', 1.25rem, 1.25rem);
}

.icon-dollar-white {
    @include bgImage('svg-pangolin/Icon-dollar-white-24.svg', 1.25rem, 1.25rem);
}

.btn:hover {
    .icon-eth-gradient {
        background-image: url(../../../assets/img/svg-pangolin/Icon-ETH-white-24.svg);
    }

    .icon-dollar-gradient {
        background-image: url(../../../assets/img/svg-pangolin/Icon-dollar-white-24.svg);
    }
}

.icon-filter {
    @include bgImage('svg-pangolin/Icon-filter-24.svg', 1.25rem, 1.25rem);
}
