.dropdown,
.dropup{
  .dropdown-toggle::after{
      display: none;
    }
    .dropdown-menu{
      .dropdown-item.active{
        background-color: inherit;
        color: inherit;
        text-decoration: inherit;
      }
    }
}
