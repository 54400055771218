.swiper-container {
    padding-bottom: 4rem !important;

    .swiper-pagination-bullet {
        width: 15px;
        height: 15px;
        padding: 2px;
        background: linear-gradient(118deg, #FE1080, #6B40FF);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        opacity: 1;
    }

    .swiper-pagination-bullet-active {
        background: linear-gradient(118deg, #FE1080, #6B40FF);
        -webkit-mask: unset;
        -webkit-mask-composite: unset;
    }
}

swiper>.swiper.s-wrapper.swiper-container-horizontal>.swiper-pagination .swiper-pagination-handle .swiper-pagination-bullet.swiper-pagination-bullet-first, swiper>.swiper.s-wrapper.swiper-container-horizontal>.swiper-pagination .swiper-pagination-handle .swiper-pagination-bullet.swiper-pagination-bullet-last {
    margin: 0 !important;
}