@import '~swiper/swiper-bundle';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "app/shared/styles/template/argon.scss";
@import "app/shared/styles/app-loading.scss";
@import "app/shared/styles/table.scss";
@import "app/shared/styles/form.scss";
@import "app/shared/services/popup/popup.scss";
@import "app/shared/styles/button-style.scss";
@import "app/shared/styles/accordion.scss";
@import '~ngx-toastr/toastr';
@import '~ngx-toastr/toastr-bs4-alert';
@import "app/shared/styles/tabs.scss";
@import "app/shared/styles/icon.scss";
@import "app/shared/styles/swiper.scss";
@import "app/shared/styles/font.scss";
@import '~ngx-sharebuttons/themes/default/default-theme';
@import 'app/core/styles/index.scss';

html {
    font-size: 16px;
}

.myPanelClass {
  margin-top: 30px !important;
}

.no-data {
    line-height: 2.5rem;
    text-align: center;
    color: #848484;
}

.c-pointer {
    cursor: pointer;
}

.show-form-error .ng-invalid {
    border: 1px solid red !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.cdk-overlay-container {
    z-index: 3000;
}

.toast-success {
    border: 1px solid #176b47;
}

.toast-message {
    color: #176b47 !important;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.2);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

body {
    font-family: 'Poppins', sans-serif !important;
    background-color: $body-bg;
    color: #BABABA;
}

a {
    color: #FE1080;
}

.primary-text {
    color: #A2A3A8;
}

.white-text {
    color: #FFFFFF;
}

.magenta-text {
    color: #FE1080;
}

.gradient-text {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;

    &.horizontal-two-color {
        background: linear-gradient(118deg, #FE1080, #6B40FF);
    }

    &.horizontal {
        background: linear-gradient(118deg, #FE1080, #6B40FF, #21D4FD);
    }

    &.gradient-container {
        background: linear-gradient(140deg, #FE1080, #6B40FF, #21D4FD);
    }
}

.gradient-capsule {
    color: #FFFFFF;
    padding: 0.5rem 3.25rem 0.5rem 1.25rem;
    display: flex;
    position: relative;
    z-index: 0;
    align-items: center;

    img {
        border-radius: 50%;
        position: absolute;
        width: 38px;
        height: 38px;

        &.right-img {
            right: 0;
        }

        &.left-img {
            left: 0;
        }
    }
}

.gradient-capsule:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 2px;
    border-radius: 24px;
    background: linear-gradient( 118deg, #FE1080, #6B40FF);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
}

.gradient-text {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;

    &.horizontal {
        background: linear-gradient(118deg, #FE1080, #6B40FF, #21D4FD);
    }

    &.gradient-container {
        background: linear-gradient(140deg, #FE1080, #6B40FF, #21D4FD);
    }
}

.gradient-border {
    color: #FFFFFF;
    padding: 0.5rem 3.25rem 0.5rem 1.25rem;
    position: relative;
    z-index: 0;
    align-items: center;
}

.gradient-border:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 2px;
    border-radius: 14px;
    background: linear-gradient( 118deg, #FE1080, #6B40FF);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
}

.gradient-seperator {
    position: relative;
}

.gradient-seperator:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    padding: 1px;
    background: linear-gradient( 270deg, #FE1080, #6B40FF);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
}

.noselect {
    user-select: none;
}

.Chef::before {
    content: "\1F468\200D\1F373";
}

.Featured::before {
    content: "\1F460";
}

.Music::before {
    content: "\1F3B5";
}

.Sports::before {
    content: "\1F3C0";
}

.Art::before {
    content: "\1F3A8";
}

.Games::before {
    content: "\1F3AE";
    font-size: 16px;
}

.Others::before {
    content: "\2728";
    font-size: 16px;
}

.autocomplete-drop {
    background: #010523;
    border: 1px solid #34374F;
    background: #010523;
    border: 1px solid #34374F;
    color: white;
    font-weight: bold;

    .mat-option {
        margin: 1rem 0 1rem 0;

        .mat-option-text {
            color: white;

            .option-detail-column {
                display: flex;
                flex-direction: column;

                .asset-name {
                    line-height: 28px;
                }

                .asset-creator {
                    line-height: 16px;
                    color: #BABABA;
                    font-weight: normal;
                }
            }
        }
    }

    .mat-option.mat-selected:not(.mat-active):not(:hover) {
        background: #010523;
    }

    .option-footer {
        position: sticky;
        bottom: 0;
        background: #010523;
        margin: 0 1rem;
    }
}

.mat-checkbox-checked.mat-accent
.mat-checkbox-background {
    background-color: #FE1080;
}

.mat-checkbox-ripple .mat-ripple-element {
    background-color: #004FAC !important;
}

.mat-checkbox-frame {
    border: 1px solid #BABABA;
}

.mat-checkbox-label {
    color: #BABABA;
    font-weight: 600;
    font-size: 0.8125rem;
}

.mat-checkbox-checkmark-path {
    stroke: black !important;
}

.mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: none;
}

.container-fluid {
    padding-left: 45px;
    padding-right: 45px;

    @media (max-width: 767px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.container-fluid .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
}
