.table-holder {
    display: flex;
    flex-direction: column;
    max-height: 300px;
    min-width: 300px;

    .mat-table {
        overflow: auto;
        max-height: 300px;
    }

    .mat-row {
        background-color: #fbfdff;
    }

    .mat-cell, .mat-header-cell {
        padding: 0 1.3rem;
    }
}
