.featured-nft {
    cursor: pointer;

    .nft-hover {
        background-color: rgba(0, 0, 0, 0.4);
        height: 100%;
        left: 0;
        margin-left: 15px;
        opacity: 0;
        padding-left: 15px;
        padding-right: 15px;
        pointer-events: none;
        position: absolute;
        top: 0;
        transition: opacity .3s ease-in-out;
        width: 100%;

        .nft-title {
            bottom: 16px;
            color: white;
            font-size: 20px;
            font-weight: bold;
            left: 16px;
            position: absolute;
        }
    }

    &:hover {
        .nft-hover {
            opacity: 1;
        }
    }
}

.profile-container {
    align-items: center;
    display: flex;

    .profile-picture {
        cursor: pointer;
        margin-right: 10px;
        position: relative;
        width: 46px;

        .profile-image {
            border-radius: 50%;
            border: 2px solid white;
            height: 46px;
            object-fit: cover;
            width: 46px;
        }

        .verified-check {
            background-color: white;
            border-radius: 50%;
            bottom: 0;
            position: absolute;
            right: 0;
            width: 15px;
        }
    }

    .profile-name {
        color: white;
        cursor: pointer;
        font-size: 20px;
        font-weight: 600;
    }
}

@media (max-width: 768px) {
    .featured-nft {
        .nft-hover {
            background: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(0,0,0,0) 78%, rgba(0,0,0,1) 100%);
            opacity: 1;
        }
    }
}
