.back-holder {
    display: flex;
    margin-bottom: 1.5rem;
}

.action {
    position: absolute;
    right: 0;
    width: 6em;
}

.action-icon {
    padding: 0 0.2rem;
    cursor: pointer;
    height: 1.5rem;
}

.edit {
    position: absolute;
    right: 0;
    //width: 12.5rem;
    cursor: pointer;
}

.delete {
    position: absolute;
    right: 0;
    width: 50px;
    height: 120px;
    cursor: pointer;
}

$gray: #fcfcfc;
$yellow: #ffaa00;

$num-of-tabs: 4;

@mixin tabs {
    @for $i from 1 through $num-of-tabs {
        &:nth-of-type(#{$i}) {
            &:checked {
                ~ .slide {
                    left: calc((70% / #{$num-of-tabs}) * #{$i - 1});
                }
            }
        }
    }
}

.tab-wrap {
    width: 100%;
    position: relative;
    display: flex;
    top: 2rem;
}

input[type="radio"][name="tabs"] {
    position: absolute;
    z-index: -1;
    visibility: hidden;

    &:checked {
        + .tab-label-content {
            label {
                color: #364045;
                font-weight: 700;
            }

            .tab-content {
                display: block;
            }
        }
    }

    @include tabs;

    &:first-of-type {
        &:checked {
            ~ .slide {
                left: 0.5rem;
            }
        }
    }
}

label {
    cursor: pointer;
    color: #7c8589;
    //background-color: $gray;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    //justify-content: center;
    //text-align: center;
    //height: 3.5rem;
    transition: color 0.2s ease;
    //width: 100%;
}

.slide {
    background: $yellow;
    width: calc(20% / #{$num-of-tabs});
    height: 0.25rem;
    position: absolute;
    left: 0;
    top: calc(100% - 0.2rem);
    transition: left 0.3s ease-out;
}

.tab-label-content {
    width: 17.5%;

    .tab-content {
        position: absolute;
        left: 0rem;
        line-height: 130%;
        display: none;
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .tab-wrap {
        width: 80%;
        margin-left: 10%;
        top: -6.625rem;
    }
}

.tabs {
    text-align: left;
    border-bottom: 2px solid #ededed;

    .tab {
        border: transparent;
        background-color: transparent;
        margin-right: 5.375rem;
        font-weight:600;
        color: var(--dark-grey);
        font-size: 0.875rem;
        cursor: pointer;

        .tab:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
            width: 2.5rem;
            height: 6px;
        }

        &.selected {
            color: var(--red);
            font-weight: 700;
            border-bottom: 3px solid var(--red) !important;
            padding-bottom: 0.8125rem;
        }
    }
}

.horizontal-menu {
    display: flex;

    .menu, .menu-action {
        display: flex;

        .menu-item {
            padding: 0 1.25rem;
            color: #A2A3A8;
            font-weight: 500;
            font-size: 0.875rem;
            position: relative;

            &:hover {
                cursor: pointer;
                color: #FFFFFF;
            }

            &.active {
                margin-bottom: -0.6rem !important;
                color: #FFFFFF;
                font-weight: 600;
            }

            &.active:before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 2px;
                margin-bottom: -0.6rem !important;
                background: linear-gradient( 118deg, #FE1080, #6B40FF);
                -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                -webkit-mask-composite: destination-out;
            }
        }
    }
}

.vertical-menu {
    display: flex;

    .menu, .menu-action {
        display: flex;
        flex-direction: column;

        .menu-item {
            padding: 1rem;
            color: #A2A3A8;
            font-weight: 500;
            font-size: 0.875rem;
            position: relative;

            &:hover {
                cursor: pointer;
                color: #FFFFFF;
            }

            &.active {
                color: #FFFFFF;
                font-weight: 600;
            }

            &.active:before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 2px;
                background: linear-gradient( 118deg, #FE1080, #6B40FF);
                -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                -webkit-mask-composite: destination-out;
            }
        }
    }
}

.active-item {
    color: #004FAC;
}
