.color-header {
    font-size: 4.5rem;
    font-weight: 800;
    background: transparent linear-gradient(140deg, #FE1080 0%, #AD66FF 50%, #21D4FD 100%) 0% 0% no-repeat padding-box;
    background-clip: text;
    text-align: center;
    -webkit-text-fill-color: transparent;

    @media (max-width: 768px) {
        font-size: 3rem;
        text-align: left;
    }
}

.gradient {
    -webkit-text-fill-color: transparent;
    background: transparent linear-gradient(140deg, #FE1080 0%, #AD66FF 50%, #21D4FD 100%) 0% 0% no-repeat padding-box;

    // Ordering dependency style
    background-clip: text;
}
