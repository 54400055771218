.modal .modal-content {
    background-color: #010523;
    box-shadow: none;
}

.modal-backdrop {
    background-color: #34374FCC;
}

.modal-header {
    border-bottom: 1px solid #34374F;
    align-items: center;
    border-radius: 1rem 1rem 0 0;
}

.modal-body {
    border-radius: 0 0 1rem 1rem;
}

.modal-backdrop.backdrop-popup {
    z-index: 2000;
}

.modal.modal-popup {
    z-index: 2010;

    .modal-dialog {
        max-width: 400px;
    }
}

.modal-backdrop.menu-backdrop-popup {
    z-index: 2000 !important;
}

.modal.menu-modal-popup {
    z-index: 2010;
}

.modal-title {
    color: #FFF;
}

//full screen modal
@media (max-width: 768px) {
    .modal.modal-full-screen .modal-dialog {
        height: 100vh;
        width: 100%;
        margin: 0;
    }

    .modal.modal-full-screen .modal-content {
        height: 100vh;
        width: 100%;
        background-color: transparent;
    }

    .modal.modal-full-screen .modal-body {
        overflow-y: auto;
    }
}
