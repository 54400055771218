//
// Dropdown
//

// General styles

.dropdown,
.dropup,
.dropright,
.dropleft {
    display: inline-block;
}

.dropdown-menu {
    min-width: 20rem;
    background-color: rgb(1, 5, 35);
    border: 1px solid #34374F;
    transform: translate(-138px, 48px) !important;

    .dropdown-item {
        padding: .5rem 1rem;
        font-size: $font-size-sm;
        color: #8898aa;

        &:hover {
            color: #FFFFFF;
            background-color: #FFFFFF14;
        }

        > i,
        > svg {
            margin-right: 1rem;
            font-size: 1rem;
            vertical-align: -17%;
        }
    }
}

.dropdown-header {
    padding-left: 1rem;
    padding-right: 1rem;
    color: $white;
    font-size: .625rem;
    text-transform: uppercase;
    font-weight: 700;
    cursor: default;
}


// Media components inside dropdown link

.dropdown-menu {
    a.media {

        > div {
            &:first-child {
                line-height: 1;
            }
        }

        p {
            color: $gray-600;
        }

        &:hover {
            .heading,
            p {
                color: theme-color("default") !important;
            }
        }
    }
}

.dropdown-divider {
    border-top: 1px solid #34374F;
}

// Size variations

.dropdown-menu-sm {
    min-width: 100px;
    border: $border-radius-lg;
}

.dropdown-menu-lg {
    min-width: 260px;
    border-radius: $border-radius-lg;
}

.dropdown-menu-xl {
    min-width: 450px;
    border-radius: $border-radius-lg;
}

.mat-form-field {
    padding: 0;
    border-radius: 5px;
    border-color: #DFE8F1;

    .mat-form-field-underline {
        display: none;
    }

    .mat-form-field-wrapper {
        padding-bottom: 0;
    }

    .mat-form-field-label {
        display: none;
    }

    .mat-form-field-infix {
        border-top: 0;
        padding: 0.625rem 0;
    }

    .mat-form-field-prefix {
        font-size: 1.4375rem;
        padding-top: 0.125rem;
    }

    &.mat-form-field-disabled {
        background-color: #e9ecef !important;
    }

    &.cust-title {
        .mat-select-arrow {
            color: #2F2F2F !important;
        }

        span {
            color: #2F2F2F !important;
            font-weight: 700;
        }
    }
}

.mat-select-search-panel {
    .mat-select-search-input {
        padding: 1rem 2.25rem 1rem 1rem !important;
    }

    .mat-select-search-clear {
        width: 1.6rem;
        height: 1.6rem;
        top: 50% !important;
        transform: translateY(-50%);
        right: 0.8rem;
        outline: none;
        line-height: 24px;

        .mat-button-focus-overlay {
            display: none;
        }
    }
}

.mat-select-value, .mat-select-arrow {
    color: #FFFFFF;
}

.gradient-capsule {
    .mat-select-value {
        font-weight: bold;
        padding-left: 0.75rem;
    }

    .mat-select-arrow {
        border-left: none !important;
        border-right: none !important;
        border-top: none !important;
        width: 2rem !important;
        height: 2rem !important;
        background-image: url(../../../../../../assets/img/svg-pangolin/icon-shrink-gradient.svg);
        margin: -3px 1px !important;
    }
}

.app-dropdown-panel {
    background-color: #010523;
    border: 1px solid #34374F;

    .mat-option-text {
        color: #FFFFFF;
        font-weight: bold;
    }
}

.cdk-overlay-container .cdk-overlay-pane .app-dropdown-panel {
    transform: translateX(10px) translateY(35px) !important;
}
