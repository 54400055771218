.navbar-light .navbar-nav .nav-link {
    color: #bababa;
    cursor: pointer;
    padding-bottom: 4px;
    position: relative;

    &:hover {
        color: #bababa;
    }
}

.navbar-light .navbar-nav .active .nav-link {
    color: white;

    &::after{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 3px;
        background: linear-gradient(270deg, #FE1080, #6B40FF);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask-composite: destination-out;
    }
}
