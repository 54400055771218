mat-expansion-panel-header{
    padding: 0 4px !important;
}

.mat-expansion-panel {
    background: none;
    color: #FFFFFF;
}

.mat-expansion-panel-content {
    background: none
}

.mat-expansion-indicator::after {
    color: #FFFFFF;
}

.panel-description {
    .mat-expansion-panel-content {
        overflow-y: scroll;
        height: 170px;
    }
}

.mat-expansion-panel-body {
    padding: 0 4px !important;
}
