form {
    .col-term {
        padding: 2rem 3.125rem;
        line-height: 1.875rem;
    }

    .btn-submit {
        color: white;
        background: black;
        float: right;
        cursor: pointer;
        box-shadow: none !important;
    }

    .btn-submit:hover {
        background: #454747;
    }

    .btn-upload {
        color: white;
        background: #02c7b6;
        box-shadow: none !important;
    }

    .btn-upload:hover {
        background: #039689;
    }

    mat-select {
        font-weight: bold;
    }

    &.show-form-error {
        .ng-invalid,
        .ng-invalid-manual {
            border: 1px solid #FA3434 !important;
        }
    }
}

.error-msg {
    color: #FA3434;
    font-size: 0.75rem;
    padding-top: 0.5rem;
}
