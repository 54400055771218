@font-face {
    font-family: Poppins;
    src: url("/assets/font/poppins-font/Poppins-Thin.ttf");
    font-weight: 100;
}

@font-face {
    font-family: Poppins;
    src: url("/assets/font/poppins-font/Poppins-ExtraLight.ttf");
    font-weight: 200;
}

@font-face {
    font-family: Poppins;
    src: url("/assets/font/poppins-font/Poppins-Light.ttf");
    font-weight: 300;
}

@font-face {
    font-family: Poppins;
    src: url("/assets/font/poppins-font/Poppins-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: Poppins;
    src: url("/assets/font/poppins-font/Poppins-Medium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: Poppins;
    src: url("/assets/font/poppins-font/Poppins-SemiBold.ttf");
    font-weight: 600;
}

@font-face {
    font-family: Poppins;
    src: url("/assets/font/poppins-font/Poppins-Bold.ttf");
    font-weight: 700;
}

@font-face {
    font-family: Poppins;
    src: url("/assets/font/poppins-font/Poppins-ExtraBold.ttf");
    font-weight: 800;
}

@font-face {
    font-family: Poppins;
    src: url("/assets/font/poppins-font/Poppins-Black.ttf");
    font-weight: 900;
}