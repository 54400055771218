@mixin button-color($background, $color: #FFFFFF, $padding: 0.75rem 1.25rem) {
    color: $color;
    background: $background;
    padding: $padding;
    cursor: pointer;
}

@mixin gradient-button-color($padding: 0.75rem 1.25rem, $gradientDeg, $color1, $color2) {
    color: #FFFFFF;
    background: linear-gradient($gradientDeg, $color1, $color2);
    padding: $padding;
    cursor: pointer;
}

.btn {
    border-radius: 24px;
}

.btn.active {
    background: linear-gradient( 118deg, #FE1080, #6B40FF);
    border: 1px solid black;
}

.btn:hover {
    color: #FFFFFF;
}

.btn-solid {
    @include gradient-button-color(0.75rem 1.25rem, 118deg, #FE1080, #6B40FF);
    border: none;
}

.btn-solid:disabled {
    background: #171938;
}

.btn-alt {
    color: #FFFFFF;
    padding: 0.75rem 1.25rem;
    display: inline-block;
    position: relative;
    z-index: 0;
}

.btn-alt:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 2px;
    border-radius: 24px;
    background: linear-gradient( 118deg, #FE1080, #6B40FF);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
}

.btn-alt:hover {
    @include gradient-button-color(0.75rem 1.25rem, 118deg, #FE1080, #6B40FF);
    border: 1px solid black;
}

.btn-magenta {
    @include button-color(transparent, #FE1080, 0.625rem 1rem);
}

.btn-magenta:hover {
    background-color: #FE108026;
    color: #FE1080;
    text-shadow: 0 0 1px #FE1080;
}

.btn-connect {
    @include gradient-button-color(1.5rem 1.25rem, 103deg, #FF5C00, #FFDC24);
    border: none;
    width: 100%;
    border-radius: 1rem;
    text-align: left;

    img {
        position: absolute;
        top: 0.625rem;
        right: 1.25rem;
    }
}

.btn-login {
    @include button-color(#2081e2, #FFFFFF, 0.75rem 1.25rem);
    max-width: 150px;
    width: 100%;
}

.btn-category-selected {
    @include button-color(#e5e8eb, #353840, 0.35rem 1.25rem);
}

.btn-category-deselected {
    @include button-color(transparent, #8a939b, 0.35rem 1.25rem);

    &:hover {
        @include button-color(#e5e8eb, #353840, 0.35rem 1.25rem);
    }
}

.btn-buy {
    @include button-color(#2081e2, #FFFFFF, 0.75rem 1.25rem);
    max-width: 100%;

    &:hover {
        color: #FFFFFF;
    }
}

.btn-blue {
    @include button-color(#2081e2, #FFFFFF, 0.75rem 1.25rem);
    max-width: 150px;
    width: 100%;

    &:hover {
        color: #FFFFFF;
    }
}

.btn-blue-alt {
    @include button-color(#FFFFFF, #2081e2, 0.75rem 1.25rem);
    border: 1px solid #2081e2;
    max-width: 150px;
    width: 100%;
}

.btn-blue-alt-small {
    @include button-color(#FFFFFF, #2081e2, 0.25rem 1rem);
    border: 1px solid #2081e2;
    max-width: 150px;
    width: 100%;
    font-size: 0.625rem;
    font-weight: 500;
}

.btn-purple {
    @include button-color(transaprent, #A2A3A8, 0.625rem 1.625rem);
    border-radius: 24px;
    border: 1px solid #A2A3A8;
    height: 40px;

    &:hover {
        color: #FFFFFF;
        border: 1px solid #6B40FF;
        background: #6b40ff24;
    }
}

.btn-copy {
    @include button-color(transparent, #2081e2, 0.625rem 0.5rem);
    position: absolute;
    right: 1rem;

    &:hover {
        color: #2081e2;
    }
}

.btn-clipboard {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: none !important;
}

.st-custom-button {
    @include button-color(transaprent, #A2A3A8, 0.625rem 1.25rem 0.625rem 2.5rem);
    border-radius: 24px;
    border: 1px solid #A2A3A8;

    &:hover, &.share-active {
        color: #FFFFFF;
        border: 1px solid #6B40FF;
        background: #6b40ff24;

        .icon-share {
            background-image: url(../../../assets/img/svg-pangolin/Icon-share-blue-24.svg);
        }
    }
}
